<template>
  <div class="loader">
    <div style="width: 100%;">
      <div style="display: flex;justify-content: center;">
        <div class="logo-round-parent">
          <div class="logo-left-sub"></div>
          <div class="logo-left-sub"></div>
          <div class="logo-left-sub"></div>
        </div>
        <div class="hide-image">
          <img src="../../assets/3xlogo.svg" alt="" />
        </div>
        <div class="logo-round-parent">
          <div class="child-2"></div>
        </div>
      </div>
    </div>
    <div class="loading">
      <div>
        <span class="loading-text-words1">L</span>
        <span class="loading-text-words1">o</span>
        <span class="loading-text-words1">a</span>
        <span class="loading-text-words2">d</span>
        <span class="loading-text-words2">i</span>
        <span class="loading-text-words2">n</span>
        <span class="loading-text-words3">g</span>
        <span class="loading-text-words3">.</span>
        <span class="loading-text-words3">.</span>
        <span class="loading-text-words3">.</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Spinner"
};
</script>

<style scoped>
.loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo-round-parent {
  display: flex;
  height: 60px;
  flex-direction: column;
  justify-content: space-around;
}

.logo-left-sub {
  background: #ad1927;
  opacity: 0.7;
  height: 12px;
  width: 12px;
  animation: expandLogo 1s infinite;
  border-radius: 50%;
}

@keyframes expandLogo {
  0%,
  33% {
    width: 12px;
  }

  34%,
  66% {
    width: 30px;
    border-radius: 15px;
  }

  67%,
  100% {
    opacity: 1;
    width: 50px;
    border-radius: 15px;
  }
}

.child-2 {
  height: 12px;
  width: 12px;
  background: #595959;
  opacity: 0.7;
  border-radius: 50%;
  animation: moveDiv 1s infinite;
}

@keyframes moveDiv {
  0%,
  33% {
    transform: translate(0px, 0px);
  }

  34%,
  66% {
    opacity: 1;
    transform: translate(10px, -11px);
  }

  67%,
  100% {
    opacity: 1;
    transform: translate(33px, -23px);
  }
}

.hide-image {
  height: 60px;
  width: 20px;
  opacity: 0;
  transform: translate(15px, 0px);
  animation: showImage 1s infinite;
}

img {
  height: 100%;
  width: auto;
}

.loading {
  margin-left: 43px;
  margin-top: 22px;
}

@keyframes showImage {
  0%,
  80% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
}

span {
  font-weight: 600;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  color: #595959;
  margin-left: 5px;
}

.loading-text-words1 {
  animation: animateColor1 2s infinite;
}

.loading-text-words2 {
  animation: animateColor2 2s infinite;
}

.loading-text-words3 {
  animation: animateColor3 2s infinite;
}

@keyframes animateColor1 {
  0%,
  33% {
    color: #595959;
  }

  34%,
  66% {
    color: #d6d6d6;
  }

  67%,
  100% {
    color: #d6d6d6;
  }
}

@keyframes animateColor2 {
  0%,
  33% {
    color: #d6d6d6;
  }

  34%,
  66% {
    color: #595959;
  }

  67%,
  100% {
    color: #d6d6d6;
  }
}

@keyframes animateColor3 {
  0%,
  33% {
    color: #d6d6d6;
  }

  34%,
  66% {
    color: #d6d6d6;
  }

  67%,
  100% {
    color: #595959;
  }
}
</style>
