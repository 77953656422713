<template>
  <div>
    <div class="relativeclass">
      <div v-if="Loaderstatus" class="spinnerstyle">
        <Spinner />
      </div>
    </div>
    <div class="agentsndext-maincontainer">
      <div class="d-flex justify-content-center">
        <div class="agent-ext-page">
          <div class="systemcheck-heading">
            Permission to Add Agent and Extension
          </div>
          <div class="d-flex justify-content-between">
            <div class="agentcontentandext-left-container mt-3">
              <div class="">
                <div class="ext_step">Step 1</div>
                <div class="ext_input mt-2">Open the Chrome Web Store.</div>
                <div class="ext_step mt-3">Step 2</div>
                <div class="ext_input mt-2">
                  Search for "Simplifycv" in the search bar and select the
                  extension.
                </div>
                <div class="mt-3">
                  <img src="../assets/images/agentextension.png" alt="" />
                </div>
                <div class="ext_step mt-3">Step 3</div>
                <div class="ext_input mt-2">
                  By clicking on "Add to chrome" you can add the extension.
                </div>
                <div class="mt-3">
                  <img
                    src="../assets/images/agentextensionsimplify.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="agentcontentandext-right-container mt-3">
              <div class="">
                <div class="ext_step">Step 1</div>
                <div class="ext_input mt-2">Click on 'Download' button.</div>
                <div class="ext_step mt-3">Step 2</div>
                <div class="ext_input mt-2">
                  Follow the on-screen steps to install the application.
                </div>
                <b-row>
                  <b-col>
                    <img src="../assets/images/agentinstfirst.png" alt="" />
                  </b-col>
                  <b-col>
                    <img
                      src="../assets/images/agentinstsecond.png"
                      class="mt-5"
                      alt=""
                    />
                  </b-col>
                </b-row>
                <div class="ext_step mt-3">Step 3</div>
                <div class="ext_input mt-2">
                  You can either open the application from the desktop or search
                  for it from the search bar.
                </div>
                <div class="ext_step mt-3">Step 4</div>
                <div class="ext_input mt-2">
                  Permit access controls and permissions.
                </div>
                <div class="ext_step mt-3">Step 5</div>
                <div class="ext_input mt-2">
                  The application will appear in the system tray if it was
                  installed correctly.
                </div>
                <div>
                  <img src="../assets/images/agentsystemtray.png" alt="" />
                </div>
                <div class="ext_step mt-3">Step 6</div>
                <div class="ext_input mt-2">
                  Right-click on the application in the System tray and select
                  Start service.
                </div>
              </div>
            </div>
          </div>
          <b-row>
            <b-col class="text-right">
              <div class="extension_button mt-2">
                <b-button class="addbtn pr-3 pl-3" @click="downloadExtension()"
                  >Download</b-button
                >
              </div>
            </b-col>
            <b-col class="text-right">
              <div class="relativeclas mt-2">
                <div
                  class="sec-addcheckboxtext pointer mr-3"
                  @click="showdatacard = !showdatacard"
                >
                  <b-button class="addbtn pr-3 pl-3"
                    >Download
                    <img
                      class="ml-1 add-arrow"
                      src="../assets/whitedownarrow.svg"
                      alt=""
                  /></b-button>
                </div>
                <div v-if="showdatacard">
                  <div class="sec-adddata-card">
                    <div
                      class="qb-dropdown-option-sing d-flex align-items-center"
                      v-for="(item, index) in agents"
                      :key="index"
                    >
                      <div class="pointer" @click="downloadAgentBtn(item)">
                        Download {{ item.platform }} - {{ item.bit }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="extension_caution mt-3 d-flex justify-content-around">
        <div class="d-flex text-center">
          <div>
            <img src="../assets/icons/extensioncaution.svg" alt="" />
          </div>
          <div class="ext_input ml-2">
            If you have already added the permissions, refresh the page to
            continue
            <br />
            If you are taking this test on incognito, Please go to manage
            extension on your browser and allow simplifycv proctoring extension
            to work on incognito.
            <span class="ext_permission ml-2"> Refresh permission </span>
          </div>
        </div>

        <div
          class="d-flex justify-content-end mb-3"
          v-if="verifyAgentAndExtension"
        >
          <b-button
            class="addbtn ml-5 pr-3 pl-3"
            v-if="testDetails.config.allowImageAndIdCapture"
            @click="nextSelfiePage()"
            >Next</b-button
          >
          <b-button
            class="addbtn ml-5 pr-3 pl-3"
            v-else-if="testDetails.config.typingTest"
            @click="goToTypingTest()"
            >Next</b-button
          >
          <b-button
            class="addbtn ml-5 pr-3 pl-3"
            v-else
            @click="nextdosanddonts()"
            >Next</b-button
          >
        </div>
        <div
          class="d-flex justify-content-end mb-3"
          v-if="!verifyAgentAndExtension"
        >
          <b-button class="addbtn ml-5 pr-3 pl-3" @click="verifyFunc()"
            >Verify</b-button
          >
        </div>
      </div>
    </div>
    <b-modal id="extmodal" centered hide-footer hide-header>
      <div class="modalheading d-flex align-items-center">Alert !</div>
      <div @click="$bvModal.hide('extmodal')" class="xMarkCancel">
        <img src="../assets/xmark.svg" alt="" />
      </div>
      <hr />
      <div class="modal-text-body">
        <span
          >Please follow the instructions provided in this page to install and
          install the "SimplifyCV Proctoring Extension" to continue the test.
        </span>
      </div>

      <div class="d-flex align-items-center justify-content-end mt-3">
        <b-button class="addbtn" @click="$bvModal.hide('extmodal')"
          >Okay</b-button
        >
      </div>
    </b-modal>
    <b-modal id="agentmodal" centered hide-footer hide-header>
      <div class="modalheading d-flex align-items-center">Alert !</div>
      <div @click="$bvModal.hide('agentmodal')" class="xMarkCancel">
        <img src="../assets/xmark.svg" alt="" />
      </div>
      <hr />
      <div class="modal-text-body">
        <span
          >Please follow the instructions provided in this page to install and
          start "Simplify CV Proctoring Agent" and continue the test.
        </span>
      </div>

      <div class="d-flex align-items-center justify-content-end mt-3">
        <b-button class="addbtn" @click="$bvModal.hide('agentmodal')"
          >Okay</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { downloadAgent, CheckAgentService } from "../apiFunction";
import Spinner from "../components/fixedcomponents/Spinner.vue";
export default {
  name: "ActivateBrowserExtension",
  data() {
    return {
      verifyAgentAndExtension: false,
      Loaderstatus: false,
      showdatacard: false,
      agents: [],
    };
  },
  computed: {
    ...mapGetters("sectionVUEX", {
      testDetails: "getTestDetails",
    }),
  },
  components: {
    Spinner,
  },
  mounted() {
    this.downloadAgent();
  },
  methods: {
    goToTypingTest() {
      this.$router.push({
        path: "/typingtest",
      });
    },
    downloadExtension() {
      window.open(
        " https://chrome.google.com/webstore/detail/simplifycv-proctoring/gfepljemgkegiccegjbclmhmnodpieoj"
      );
    },
    async downloadAgent() {
      const res = await downloadAgent();
      if (res.status == 200) {
        this.agents = res.data.data;
      }
    },
    downloadAgentBtn(item) {
      window.open(item.downloadLink);
    },

    nextSelfiePage() {
      this.$router.push({
        path: "/snapshots",
      });
    },
    nextdosanddonts() {
      this.$router.push({
        path: "/dosanddonts",
        query: {
          testDetails: this.testDetails,
        },
      });
    },
    checkExtension(id, src, callback) {
      let e = new Image();
      e.src = "chrome-extension://" + id + "/" + src;
      e.onload = () => callback(1);
      e.onerror = () => callback(0);
    },
    verifyFunc() {
      this.Loaderstatus = true;
      CheckAgentService(this.testDetails.attemptNo)
        .then((res) => {
          if (res.data.data.AGENT_CHECK_RESPONSE) {
            console.log(res.data.data.AGENT_CHECK_RESPONSE);
            this.checkExtension(
              "gfepljemgkegiccegjbclmhmnodpieoj",
              "images/icon.png",
              (ok) => {
                if (ok == 1) {
                  this.Loaderstatus = false;
                  this.verifyAgentAndExtension = true;
                } else {
                  this.Loaderstatus = false;
                  this.$bvModal.show("extmodal");
                }
              }
            );
          }
        })
        .catch(() => {
          this.Loaderstatus = false;
          this.$bvModal.show("agentmodal");
        });
    },
  },
};
</script>
<style>
.ext_card {
  padding: 30px 50px 30px 50px;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  background-size: cover !important;
  background-repeat: no-repeat;
  filter: drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.15));
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 15px;
}
</style>
